import '../styles/styles.scss';
import 'typeface-roboto-slab';
import 'typeface-roboto';

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import classnames from 'classnames';
import MiniLogo from './miniLogo';
import settings from '../content/admin/settings.json';

const phoneUri = `tel:1${settings.phone.replace(/[^0-9]+/g, '')}`;

const Header = () => {
  const [isExpanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!isExpanded);
  };

  return (
    <header>
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <MiniLogo />
          </Link>

          <a
            role="button"
            className="navbar-burger burger"
            className={classnames(
              'navbar-burger',
              'burger',
              isExpanded && 'is-active'
            )}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={handleExpandClick}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className={classnames('navbar-menu', isExpanded && 'is-active')}>
          <div className="navbar-end">
            <Link to="/mission" className="navbar-item">
              MISSION
            </Link>
            <Link to="/produce" className="navbar-item">
              PRODUCE
            </Link>
            <Link to="/contact" className="navbar-item">
              CONTACT
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

const Footer = () => {
  return (
    <section className="footer">
      <div className="content has-text-centered">
        <p>
          Copyright © {settings.company}, {new Date().getFullYear()}
        </p>
        <p>
          em: <a href={`mailto:${settings.email}`}>{settings.email}</a> | ph:{' '}
          <a href={phoneUri}>{settings.phone}</a>
        </p>
      </div>
    </section>
  );
};

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header title={data.site.siteMetadata.title} />
      <div className="navbar" style={{ zIndex: -99 }} />
      <main className="main-content">{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
