import React from 'react';

const Page = ({ children }) => {
  return <>{children}</>;
};

Page.Header = ({ title, subtitle, ...props }) => {
  return (
    <section className="hero is-bold peak-header">
      <div className="hero-body">
        <div className="container">
          <h2 className="title">{title}</h2>
          <h3 className="subtitle">{subtitle}</h3>
        </div>
      </div>
    </section>
  );
};

Page.Content = props => {
  return (
    <section className="section">
      <div className="container">{props.children}</div>
    </section>
  );
};

export default Page;
